import React from 'react';
import { isMilestoneIncomplete, isPercentAllowed, percentFormatter } from '@utils';
import { PercentsInput } from '@components';
import { CellRenderWithError, MilestoneListColumnTypeV2 } from './common';
import { GridEditInputCell } from '@mui/x-data-grid';
import { InputBaseComponentProps } from '@mui/material';

const lenderAllowanceRate: MilestoneListColumnTypeV2 = {
  field: 'lender_allowance_rate',
  headerAlign: 'right',
  headerClassName: 'number',
  cellClassName: 'number',
  headerName: 'Lender allowance (%)',
  description: 'Cumulative % approved to fund per Lender',
  valueFormatter: (value) => percentFormatter({ returnValue: ' ', value }),
  renderCell: (params) => <CellRenderWithError params={params} />,
  renderEditCell: (props) => (
    <GridEditInputCell
      {...props}
      inputProps={{
        isAllowed: isPercentAllowed,
      }}
      inputComponent={PercentsInput as unknown as React.ElementType<InputBaseComponentProps>}
    />
  ),
  minWidth: 120,
  editableByMilestone: (row) =>
    row.isEditableV2 && isMilestoneIncomplete(row, 'approved_adjustments'),
};

export default lenderAllowanceRate;
